// npm
import React, { memo } from 'react'
import { Box, BoxProps, Container, styled } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import loadable from '@loadable/component'

// components
import TextContentGridLayout, {
  SectionTextContentGridLayoutProps,
} from '@molecules/text/TextContentGridLayout'

const BulletColumnCard = loadable(
  () => import('@molecules/cards/BulletColumnCard')
)
const IconCard = loadable(() => import('@molecules/cards/IconCard'))

interface Props extends SectionTextContentGridLayoutProps, BoxProps {
  cardType: 'icon-bullet-cell' | 'column'
  items: Queries.DatoCmsBulletColumn[] | Queries.DatoCmsIconBulletPoint[]
}

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
}))

const SectionBulletColumns = ({
  cardType,
  smallHeading,
  mainHeading,
  text,
  items,
  ...props
}: Props) => {
  return (
    <Wrapper
      component="section"
      overflow="hidden"
      bgcolor="secondary.dark"
      py={{ xs: 6, md: 10 }}
      {...props}
    >
      <Container maxWidth="xl">
        <TextContentGridLayout
          smallHeading={smallHeading}
          mainHeading={mainHeading}
          text={text}
        />

        <Grid container spacing={0}>
          {items.map(
            (
              item:
                | Queries.DatoCmsBulletColumn
                | Queries.DatoCmsIconBulletPoint,
              index: number
            ) => {
              if (cardType === 'column') {
                const column = item as Queries.DatoCmsBulletColumn
                return (
                  <Grid xs={12} xl={4} key={column.originalId}>
                    <BulletColumnCard
                      useAlternateColor={index % 2 === 0}
                      heading={column.heading}
                      bullets={column.bulletPoints}
                    />
                  </Grid>
                )
              }
              if (cardType === 'icon-bullet-cell') {
                const cell = item as Queries.DatoCmsIconBulletPoint
                return (
                  <Grid xs={12} md={6} xl={4} key={cell.originalId}>
                    <IconCard
                      iconUrl={cell.icon.url}
                      useAlternateColor={index % 2 === 0}
                      heading={cell.text}
                      vCenter
                    />
                  </Grid>
                )
              }
            }
          )}
        </Grid>
      </Container>
    </Wrapper>
  )
}

export default memo(SectionBulletColumns)
